import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const adService = {
  get,
  save,
  list,
  base64,
}

function base64(id, type, img_ext) {
    let url = config.api + `/v1/admin/ad/base64`;

    let obj = { id, type, img_ext };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}
function get(id) {
    let url = config.api + `/v1/admin/ad/get`;
  
    let obj = { id };
  
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
  }
  function save(loggedId,id,id_partner,isactive
    ,startdate,enddate,title
    ,content,link,type
    ,image,campaign,name
    ,priority,index,saveimage,imageFormData,imageDesktop,imageMobile) {
    let url = config.api + `/v1/admin/ad/save`;

    var formData = new FormData();
    formData.append("loggedId", loggedId);
    formData.append("id", id);
    formData.append("id_partner", id_partner);
    formData.append("isactive", isactive);
    formData.append("startdate", startdate);
    formData.append("enddate", enddate);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("link", link);
    formData.append("type", type);
    formData.append("image", image);
    formData.append("campaign", campaign);
    formData.append("name", name);
    formData.append("priority", priority);
    formData.append("index", index);
    formData.append("saveimage", (saveimage == true ? 1 : 0));
    formData.append("imageFormData", imageFormData);
    formData.append("imageDesktop", imageDesktop);
    formData.append("imageMobile", imageMobile);

    // let obj = { loggedId,id,id_partner,isactive
    //     ,startdate,enddate,title
    //     ,content,link,type
    //     ,image,campaign,name
    //     ,priority,index,saveimage: (saveimage == true ? 1 : 0), imageFormData };
  
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, formData, { emulateJSON: true, headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
  }
  function list(text, currentPage, perPage) {
    let url = config.api + `/v1/admin/ad/list`;

    url = config.system.applyPagination(url, currentPage, perPage);
  
    let obj = { text };
  
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
  }
      