<template>
    <div>
      <h1>Gerador de Cartaz</h1>
      <b-container>
        <b-row class="mb-3 w-70">
        <b-col>
          <b-input-group size="sm" class="mt-2">
            <b-input-group-prepend class="mr-2">
              Logo customizado:
            </b-input-group-prepend>
            <b-form-input id="Title" type="text" name="title" placeholder="Coloque um link"  v-model="customLogo">
            </b-form-input>
          </b-input-group>
          <b-input-group size="sm" class="mt-2">
            <b-input-group-prepend class="mr-2">
              Background customizado:
            </b-input-group-prepend>
            <b-form-input id="Title" type="text" name="title" placeholder="Coloque um link"  v-model="customBackground">
            </b-form-input>
          </b-input-group>
          <b-input-group size="sm" class="mt-2">
            <b-input-group-prepend class="mr-2">
              Insira o código:
            </b-input-group-prepend>
            <b-form-input id="Title" type="text" name="title" placeholder="Coloque um link"  v-model="customCode">
            </b-form-input>
          </b-input-group>
          <b-input-group style="width: 25%;" size="sm" class="mt-2">
            <b-input-group-prepend class="mr-2">
              Comunicação válida: 
            </b-input-group-prepend>
            <b-form-input id="date" type="text" name="date" v-model="customDate" v-mask="'##/##/####'"></b-form-input>
          </b-input-group>
          <b-input-group size="sm" class="mt-2">
            <b-input-group-prepend class="mr-2">
              Gearar cartaz:
            </b-input-group-prepend>
            <b-button type="button" variant="outline-success" class="ml-2" size="sm" @click="save"> Gerar
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pt-5">
          <div class="align-center mx-0 m-0" v-html="emailBody">
          </div>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button v-if="emailBody !== ''" type="button" variant="outline-success" class="mt-5" size="sm" @click="copy"> Copiar link do cartaz</b-button>
          <b-form-input v-if="emailBody !== ''" placeholder="Digite um sub título para o conteúdo"  v-model="emailBody"  value="Código HTML" id="myInput" class="mt-4">></b-form-input>
        </b-col>
      </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import { func } from "@/functions";
import VueMask from "v-mask";
// import VueUploadMultipleImage from "vue-upload-multiple-image";


Vue.use(VueMask);

export default {
  mixins: [func],
  // components: { VueUploadMultipleImage },
  name: 'generate-poster',
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: "Admin - Gerar Cartaz",
      }
    },
  },
  methods: {
    generateBodyEmail() {
      this.emailBody = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .container-poster {
            background-color: #fff;
            text-align: center;
            width: 21cm;
            height: 29.7cm;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            margin: 0 auto;
            padding: 10px 25px;
            margin-bottom: 0.5cm;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            background-image: url("`+ this.customBackground + `");
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .container-partner {
            background: #ffffff;
            width: 100%;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        .box-partner{
            display: flex;
            justify-content: space-between;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 5px;
        }

        .partner-logo, .partner-descr, .partner-qr {
            display: inline-block;
        }

        .partner-logo, .partner-qr {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        .partner-descr {
            width: 65%;
        }

        .partner-logo img {
            width: 100px;
            border-radius: 5px;
        }

        .partner-qr img {
            width: 100px;
            border-radius: 5px;
        }
       
    </style>
    <title>Cartaz</title>
</head>
<body>
   <div class="container-poster">
            <div class="container-partner">
                <div class="box-partner">
                    <div class="partner-logo">
                        <img src="` + this.customLogo + `" alt="">
                    </div>
                    <div class="partner-descr">
                        <p>Acesse <strong>corporativo.bilheteria.com.br</strong> <br>
                            Faça seu cadastro com o código <strong>`+ this.customCode +`</strong> 
                        </p>
                        <p style="font-style: italic; font-size: 14px">Comunicação válida até `+ this.customDate +` ou enquanto durarem os estoques </p>
                    </div>
                    <div class="partner-qr">
                        <img src="` + this.qrcode + `" alt="">
                    </div>
                </div>
            </div> 
   </div>
   
</body>
</html>`
    },
  save() {
        this.generateBodyEmail();
      },
  copy() {
    /* Get the text field */
    var copyText = document.getElementById("myInput");
  
    /* Select the text field */
    copyText.select();
  
    /* Copy the text inside the text field */
    document.execCommand("copy");
  
    /* Alert the copied text */
    this.toastSuccess("Texto copiado");
 }
  }, 
  data() {
    return {
      customCode: 'avon',
      qrcode: 'https://www.tixs.me/assets/images/qrcorporativo.png',
      customLogo: 'https://media.tixs.me/corporate/ori/2D1A0670-C4A6-4A9C-B770-E6960BF2A24B/original.jpg?1645724645',
      customBackground: 'https://www.tixs.me/assets/texture/cartaz_padrao.png',
      customDate: '',
      emailBody: ''
    }
  }
}
</script>